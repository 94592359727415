import request from 'utils/request';

const api = {
  INLINE_CHART_DATA: '/api/dashboard/list',
  TOP_PRODUCT: '/api/dashboard/topProduct',
  GEO_LIST: '/api/dashboard/geoList',
};

// inline chart
export function getBillInfo(query) {
  return request({
    url: api.INLINE_CHART_DATA,
    method: 'get',
    params: query,
  });
}

// top product
export function getTopProduct() {
  return request({
    url: api.TOP_PRODUCT,
    method: 'get',
  });
}

// geo list
export function getGeoList() {
  return request({
    url: api.GEO_LIST,
    method: 'get',
  });
}
