<template>
  <div class="home">
    <el-row style="margin-bottom:10px;">
      <el-card shadow="hover">
        <el-form>
          <el-row>
            <el-col :lg="4" :md="20">
              <el-form-item label="Date:" label-width="30px">
                <el-select
                  v-model="dateType"
                  @change="DateTypeChange"
                  size="small"
                  class="marign254"
                >
                  <el-option
                    v-for="item in dateTypeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="7" :md="20">
              <el-form-item label="AffiliateIds:" label-width="100px">
                <group-select
                  size="small"
                  v-model="affiliateIds"
                  :data-source="affiliatesGroupList"
                  multiple
                  clearable
                  reserve-keyword
                  filterable
                  @change="getBillInfo"
                  placeholder="请选择"
                  class="marign254"
                  style="width:100%;"
                  hasAll
                  :loading="affiliateLoading"
                />
              </el-form-item>
            </el-col>
            <el-col :lg="7" :md="20">
              <el-form-item label="SourceIds:" label-width="100px">
                <group-select
                  size="small"
                  v-model="sourceIds"
                  :data-source="sourcesList"
                  multiple
                  clearable
                  reserve-keyword
                  filterable
                  @change="getBillInfo"
                  placeholder="请选择"
                  class="marign254"
                  style="width:100%;"
                  hasAll
                  :loading="sourceLoading"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div style="width:100%;clear:both;">
          <div id="billInfocharts" ref="billInfocharts" style="width:100%;"></div>
        </div>
      </el-card>
    </el-row>
    <el-row style="margin-bottom:10px;">
      <el-card shadow="hover">
        <div style="width:100%;clear:both;">
          <div id="trafficContainer" ref="trafficContainer"></div>
        </div>
      </el-card>
    </el-row>
  </div>
</template>
<script>
  import { getBillInfo, getTopProduct, getGeoList } from 'api/dashboard';
  import echarts from '@/setup/echarts';
  import 'echarts/map/js/world.js';

  import GroupSelect from '@/components/GroupSelect';
  import { mapState, mapActions } from 'vuex';

  export default {
    components: {
      GroupSelect,
    },
    data() {
      return {
        pageSize: 10,
        cpiList: [],
        dateTypeOptions: [
          {
            label: 'RECENT 24 HOURS',
            value: 'hour',
          },
          {
            label: 'RECENT 7 DAYS',
            value: 'week',
          },
          {
            label: 'RECENT 1 MONTH',
            value: 'month',
          },
        ],
        dateType: 'hour',
        listLoading: false,
        trafficvalue: false,
        billInfoList: [],
        billInfoReportType: 'PECENT-AFF-TODAY',
        geoTrafficList: [],
        affiliateIds: [],
        sourceIds: [],
      };
    },
    async mounted() {
      this.init();

      this.getAffiliateList();
      this.getSourceIdsList();
      this.getBillInfo();
      this.getTopProduct();
      this.getgeoReportList();
    },
    destroyed() {
      window.onresize = null;
    },
    computed: {
      ...mapState('affiliate', {
        affiliatesGroupList: (state) => state.affiliatesGroupList,
        affiliateLoading: (state) => state.affiliateLoading,
      }),
      ...mapState('source', {
        sourcesList: (state) => state.sourcesList,
        sourceLoading: (state) => state.sourceLoading,
      }),
    },
    methods: {
      ...mapActions('affiliate', ['getAffiliateList']),
      ...mapActions('source', ['getSourceIdsList']),
      //打开
      openFullScreenLoadding: function() {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)',
        });
        //超时关闭
        setTimeout(() => {
          loading.close();
        }, 2000);
      },
      //关闭
      closeFullScreenLoadding: function() {
        this.$loading({}).close();
      },
      init() {
        //图表自适应
        window.onresize = () => {
          if (this.$refs.charts) {
            echarts.init(this.$refs.charts).resize();
          }
        };
        window.onresize = () => {
          if (this.$refs.billInfocharts) {
            echarts.init(this.$refs.billInfocharts).resize();
          }
        };
        window.onresize = () => {
          if (this.$refs.trafficContainer) {
            echarts.init(this.$refs.trafficContainer).resize();
          }
        };
        window.onresize = () => {
          if (this.$refs.billInfoReportcharts) {
            echarts.init(this.$refs.billInfoReportcharts).resize();
          }
        };
        window.onresize = () => {
          if (this.$refs.billInfoReportcharts1) {
            echarts.init(this.$refs.billInfoReportcharts1).resize();
          }
        };
      },
      // trafficchange(val) {
      //   if (val == true) {
      //     setTimeout(this.getbillReportInfo(this.billInfoReportType), 2000);
      //   }
      // },
      getTopProduct() {
        this.listLoading = true;
        getTopProduct().then((response) => {
          this.listLoading = false;
          if (response.code === 200) {
            // this.cpaList = response.result;
            this.cpiList = response.result;
          }
        });
      },
      getgeoReportList() {
        getGeoList().then((response) => {
          if (response.code === 200) {
            this.drawtrafficChart(response.result || []);
          }
        });
      },
      getBillInfo() {
        const param = {
          period: this.dateType,
          affiliateIds: this.affiliateIds.join(','),
          sourceIds: this.sourceIds.join(','),
        };
        getBillInfo(param).then((response) => {
          if (response.code == 200) {
            const result = response.result || [];
            if (result && result.length > 0) {
              this.drawbillInfoChart(result);
            }
          }
        });
      },
      handleSourceChange() {
        this.sourceCollTags = this.sourcesList.some((item) => item.checked);
        this.getBillInfo();
      },
      drawbillInfoChart(data) {
        let myChart = echarts.init(this.$refs.billInfocharts);
        myChart.showLoading({ text: '正在加载数据' });
        let labelArr = new Array();
        let dataArr = new Array();
        let profitDataArr = new Array();
        let clickArr = new Array();
        let conversionsArr = new Array();
        for (var i = 0; i < data.length; i++) {
          labelArr.push(data[i].date);
          dataArr.push(data[i].payout);
          profitDataArr.push(data[i].revenue);
          clickArr.push(data[i].clickCount);
          conversionsArr.push(data[i].conversionCount);
        }
        myChart.setOption({
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985',
              },
            },
          },
          legend: {
            data: ['Click', 'Conversions', 'Payout', 'Profit'],
          },
          toolbox: {
            feature: {
              saveAsImage: {
                show: true,
              },
            },
          },
          grid: {
            left: '3%',
            right: '5%',
            bottom: '3%',
            containLabel: true,
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              data: labelArr,
            },
          ],
          yAxis: [
            {
              type: 'value',
              name: 'Click',
              position: 'left',
            },
            {
              type: 'value',
              name: 'Conversion/Payout/Profit',
              position: 'right',
            },
          ],
          series: [
            {
              name: 'Payout',
              type: 'line',
              stack: '总量1',
              yAxisIndex: 1,
              data: dataArr,
            },
            {
              name: 'Profit',
              type: 'line',
              stack: '总量2',
              yAxisIndex: 1,
              data: profitDataArr,
            },
            {
              name: 'Conversions',
              type: 'line',
              yAxisIndex: 1,
              stack: 'Conversions',
              data: conversionsArr,
            },
            {
              name: 'Click',
              type: 'line',
              yAxisIndex: 0,
              stack: 'Click',
              data: clickArr,
            },
          ],
        });
        myChart.hideLoading();
      },
      drawtrafficChart(data) {
        let myChart = echarts.init(this.$refs.trafficContainer);
        myChart.showLoading({ text: '正在加载数据' });
        var text = 'Chestnut Global Traffic';
        var subtext = 'avg Revenue for the lastest 7 days';
        var maxSize = 100;
        var html = '';
        var taiwanVal = 0;
        var hongkongVal = 0;

        for (var i = 0; i < data.length; i++) {
          if (data[i].name == 'Taiwan') {
            html += '<br/><br/>' + data[i].name + ' : ' + data[i].value;
            taiwanVal = data[i].value;
            if (data[i].prod0 != null) {
              html += '<br/> <br/>' + data[i].prod0 + ' : ' + data[i].pre0.toFixed(2);
            }
            if (data[i].prod1 != null) {
              html += '<br/>' + data[i].prod1 + ' : ' + data[i].pre1.toFixed(2);
            }
            if (data[i].prod2 != null) {
              html += '<br/>' + data[i].prod2 + ' : ' + data[i].pre2.toFixed(2);
            }
            if (data[i].prod3 != null) {
              html += '<br/>' + data[i].prod3 + ' : ' + data[i].pre3.toFixed(2);
            }
            if (data[i].prod4 != null) {
              html += '<br/>' + data[i].prod4 + ' : ' + data[i].pre4.toFixed(2);
            }
          }
          if (data[i].name == 'Hong Kong, China') {
            html += '<br/><br/>' + data[i].name + ' : ' + data[i].value;
            hongkongVal = data[i].value;
            if (data[i].prod0 != null) {
              html += '<br/> <br/>' + data[i].prod0 + ' : ' + data[i].pre0.toFixed(2);
            }
            if (data[i].prod1 != null) {
              html += '<br/>' + data[i].prod1 + ' : ' + data[i].pre1.toFixed(2);
            }
            if (data[i].prod2 != null) {
              html += '<br/>' + data[i].prod2 + ' : ' + data[i].pre2.toFixed(2);
            }
            if (data[i].prod3 != null) {
              html += '<br/>' + data[i].prod3 + ' : ' + data[i].pre3.toFixed(2);
            }
            if (data[i].prod4 != null) {
              html += '<br/>' + data[i].prod4 + ' : ' + data[i].pre4.toFixed(2);
            }
          }
        }
        data.forEach(function(item) {
          if (item.value > maxSize) maxSize = item.value;
          if (item.name == 'China') {
            var val = parseInt(item.value) + parseInt(taiwanVal) + parseInt(hongkongVal);
            if (val > maxSize) maxSize = val;
            item.value = val;
          }
        });

        maxSize = maxSize * 0.6;
        myChart.setOption({
          title: {
            text: text,
            subtext: subtext,
            sublink: '#',
            left: 'center',
            top: 'top',
          },
          tooltip: {
            trigger: 'item',
            formatter: function(params) {
              var value = (params.value.toFixed(2) + '').replace(
                /(\d{1,3})(?=(?:\d{3})+(?!\d))/g,
                '$1,'
              );
              var data = params.data;
              var htm;
              htm = params.seriesName + '<br/>' + params.name + ' : ' + value;
              if (data.prod0 != null) {
                htm += '<br/> <br/>' + data.prod0 + ' : ' + data.pre0.toFixed(2);
              }
              if (data.prod1 != null) {
                htm += '<br/>' + data.prod1 + ' : ' + data.pre1.toFixed(2);
              }
              if (data.prod2 != null) {
                htm += '<br/>' + data.prod2 + ' : ' + data.pre2.toFixed(2);
              }
              if (data.prod3 != null) {
                htm += '<br/>' + data.prod3 + ' : ' + data.pre3.toFixed(2);
              }
              if (data.prod4 != null) {
                htm += '<br/>' + data.prod4 + ' : ' + data.pre4.toFixed(2);
              }
              //台湾 and 香港拼接回china
              if (params.name == 'China') {
                htm += html;
              }
              return htm;
            },
          },
          toolbox: {
            show: true,
            orient: 'vertical',
            left: 'right',
            top: 'center',
            feature: {
              dataView: { readOnly: false },
              restore: {},
              saveAsImage: {},
            },
          },
          visualMap: {
            min: 0,
            max: maxSize,
            text: ['High', 'Low'],
            realtime: false,
            calculable: true,
            inRange: {
              color: ['lightskyblue', 'yellow', 'orangered'],
            },
          },
          series: [
            {
              name: 'Grobal Traffic',
              type: 'map',
              mapType: 'world',
              roam: true,
              itemStyle: {
                emphasis: { label: { show: true } },
              },
              data: data,
            },
          ],
        });

        myChart.hideLoading();
      },
      // drawbillInfoReportChart(data) {
      //   let myChart = echarts.init(this.$refs.billInfoReportcharts);
      //   let myChart1 = echarts.init(this.$refs.billInfoReportcharts1);
      //   myChart.showLoading({ text: '正在加载数据' });
      //   myChart1.showLoading({ text: '正在加载数据' });
      //   this.getbillInfoReportParams(data, 'Payout', myChart, 0);
      //   this.getbillInfoReportParams(data, 'Profit', myChart1, 1);
      // },
      // getbillInfoReportParams(dataList, title, myChart, type) {
      //   var trafficAffNameMap = {};
      //   var trafficAffDataMap = {};
      //   var trafficTypeArr = new Array();
      //   dataList.forEach((item) => {
      //     var affNameValue = item.affiliateId + '/' + item.affiliateCompany;
      //     if (!Object.prototype.hasOwnProperty.cell(trafficAffNameMap, item.trafficType)) {
      //       trafficAffNameMap[item.trafficType] = new Array();
      //     }
      //     trafficAffNameMap[item.trafficType].push(affNameValue);

      //     trafficAffDataMap[affNameValue] = type == 0 ? item.payout : item.revenue;

      //     trafficTypeArr.push(item.trafficType);
      //   });

      //   var dataArr = new Array();
      //   var itemArr = new Array();
      //   var trafficTypeLastArr = new Array();
      //   trafficTypeArr.forEach((item, i) => {
      //     var payoutAffTotal = 0;
      //     trafficAffNameMap[item].forEach(function(affName) {
      //       itemArr.push(affName);
      //       dataArr.push({ value: trafficAffDataMap[affName], name: affName });

      //       payoutAffTotal += trafficAffDataMap[affName];
      //     });
      //     let selected = false;
      //     if (i == 0) {
      //       selected = true;
      //     }

      //     trafficTypeLastArr.push({
      //       value: payoutAffTotal,
      //       name: item,
      //       selected: selected,
      //     });
      //   });

      //   myChart.setOption({
      //     title: {
      //       text: title,
      //       x: 'center',
      //       y: 'top',
      //       top: 20,
      //       textAlign: 'left',
      //     },
      //     tooltip: {
      //       trigger: 'item',
      //       formatter: '{b}<br/>{c}({d}%)',
      //     },
      //     legend: {
      //       orient: 'vertical',
      //       x: 'right',
      //       top: 50,
      //       data: itemArr,
      //       show: false,
      //     },
      //     series: [
      //       {
      //         name: 'Traffic',
      //         type: 'pie',
      //         selectedMode: 'single',
      //         radius: [0, '30%'],

      //         label: {
      //           normal: {
      //             position: 'inner',
      //             fontStyle: 'italic',
      //           },
      //         },
      //         labelLine: {
      //           normal: {
      //             show: false,
      //           },
      //         },
      //         data: trafficTypeLastArr,
      //       },
      //       {
      //         name: 'Aff',
      //         type: 'pie',
      //         radius: ['40%', '55%'],
      //         data: dataArr,
      //       },
      //     ],
      //   });

      //   myChart.hideLoading();
      // },
      DateTypeChange(event) {
        this.dateType = event;
        this.getBillInfo();
      },
      // billInfoReportTypeChange(event) {
      //   this.getbillReportInfo(event);
      // },
    },
    beforeDestroy() {
      echarts.init(this.$refs.charts).clear();
      echarts.init(this.$refs.billInfocharts).clear();
      echarts.init(this.$refs.trafficContainer).clear();
      echarts.init(this.$refs.billInfoReportcharts).clear();
      echarts.init(this.$refs.billInfoReportcharts1).clear();
    },
  };
</script>
<style lang="scss" scoped>
  .home {
    padding: 40px;
    background: $base-gray1;
    .bg-white {
      -webkit-box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.05);
      box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.05);
      border-color: rgba(0, 0, 0, 0.05);
      margin-bottom: 20px;
      cursor: pointer;
      .icons {
        text-align: left;
        width: 160px;
        height: 130px;
        transition: 0.3s ease-in-out;
        border-radius: 3px;
        padding-left: 10px;
        .iconfont {
          font-size: 120px;
        }
      }
      .icons1 {
        &:hover {
          background: $base-white;
          color: $base-bule1;
          .chain {
            color: $base-bule1;
          }
        }
        background: $base-bule1;
        color: $base-white;
      }
      .icons2 {
        &:hover {
          background: $base-white;
          color: $base-ye;
          .chain {
            color: $base-ye;
          }
        }
        background: $base-ye;
        color: $base-white;
      }
      .icons3 {
        &:hover {
          background: $base-white;
          color: $base-pink;
          .chain {
            color: $base-pink;
          }
        }
        background: $base-pink;
        color: $base-white;
      }
      .icons-right {
        font-size: 32px;
        margin-top: 28px;
        margin-right: 16px;
        li {
          margin: 10px 0;
        }
      }
      .chain {
        color: $base-white;
        font-size: 18px;
      }
    }
    .main-center {
      width: 100%;
      margin-top: 20px;
    }
    .center-left {
      width: 100%;
      text-align: center;
      background: $base-white;
      font-size: 16px;
      color: $base-666;
      padding-bottom: 60px;
      -webkit-box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.05);
      box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.05);
      border-color: rgba(0, 0, 0, 0.05);
      .accout {
        text-align: left;
        margin: 20px;
      }
      .tou {
        margin-bottom: 20px;
        img {
          width: 85px;
          height: 85px;
          border-radius: 50%;
        }
        span {
          line-height: 25px;
        }
      }
      .mobile,
      .time {
        line-height: 30px;
      }
    }
    #charts,
    #billInfocharts,
    #trafficContainer {
      width: 100%;
      height: 426px;
      background: $base-white;
      margin-top: 20px;
      padding-top: 20px;
      -webkit-box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.05);
      box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.05);
      border-color: rgba(0, 0, 0, 0.05);
    }

    #billInfoReportcharts {
      height: 426px;
      background: $base-white;
      margin-top: 20px;
      -webkit-box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.05);
      box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.05);
      border-color: rgba(0, 0, 0, 0.05);
    }

    #billInfoReportcharts1 {
      height: 426px;
      background: $base-white;
      margin-top: 70px;
      -webkit-box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.05);
      box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.05);
      border-color: rgba(0, 0, 0, 0.05);
    }

    .users {
      margin-top: 20px;
    }
    .todulist {
      background: $base-white;
      margin-top: 20px;
      .item {
        line-height: 46px;
        border-bottom: 1px solid #ededed;
        text-align: left;
        padding-left: 40px;
        cursor: pointer;
        font-size: 16px;
      }
      .done {
        text-decoration: line-through;
        color: gray;
      }
    }
    .red_text {
      color: red;
    }
    .green_text {
      color: green;
    }
    .marign254 {
      float: right;
    }
  }
</style>
